import { useState } from "react"
import { useGapFill } from "hooks/useGapFill"
const Form = () => {
    const { generateWishlistData } = useGapFill()
    const [tier1Countries, setTier1Countries] = useState('')
    const [tier1Classes, setTier1Classes] = useState('')
    const [tier2Countries, setTier2Countries] = useState('')
    const [tier2Classes, setTier2Classes] = useState('')
    const [tier3Countries, setTier3Countries] = useState('')
    const [tier3Classes, setTier3Classes] = useState('')
    const [trademarks, setTrademarks] = useState('')
    const [file, setFile] = useState<any>(null)
    const [results, setResults] = useState<any>(null)
    const [option, setOption] = useState("")

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            const class_hierarchy = {
                'tier_1': tier1Classes?.split("\n"),
                'tier_2': tier2Classes?.split("\n"),
                'tier_3': tier3Classes?.split("\n")
            }
            const country_tiers = {
                'tier_1': tier1Countries?.split("\n"),
                'tier_2': tier2Countries?.split("\n"),
                'tier_3': tier3Countries?.split("\n")
            }
            const formData = new FormData()
            console.log(JSON.stringify(class_hierarchy), class_hierarchy)
            formData.append("file", file)
            formData.append("class_hierarchy", JSON.stringify(class_hierarchy))
            formData.append("country_tiers", JSON.stringify(country_tiers))
            formData.append("trademarks", JSON.stringify(trademarks?.split("\n")))
            const data = await generateWishlistData(formData)
            setResults(data)
        }
        catch (e) {
            console.log(e)
        }
    }
    return (
        <div className="container mx-auto mt-10 ">
            <form onSubmit={handleFormSubmit}>
                <h3 className="text-2xl text-black font-bold mb-5">Upload Portfolio Data</h3>
                <input type="file" className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl mb-3 focus:outline-none" required
                    onChange={(e) => setFile(e.target.files![0])}
                    accept=".xlsx, .xls, .csv"
                />
                <h3 className="text-2xl text-black font-bold mb-5 mt-10">Tier 1</h3>
                <div className="block sm:flex justify-between">
                    <textarea
                        rows={10}
                        className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full sm:w-[48%] text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                        placeholder="Enter Tier 1 Countries (separated by line breaks)"
                        required
                        value={tier1Countries}
                        onChange={(e) => setTier1Countries(e.target.value)}
                    ></textarea>
                    <textarea
                        rows={10}
                        className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full sm:w-[48%] text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                        placeholder="Enter Tier 1 Classes (separated by line breaks)"
                        required
                        value={tier1Classes}
                        onChange={(e) => setTier1Classes(e.target.value)}
                    ></textarea>
                </div>
                <h3 className="text-2xl text-black font-bold mb-5 mt-10">Tier 2</h3>
                <div className="block sm:flex justify-between">
                    <textarea
                        rows={10}
                        className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full sm:w-[48%] text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                        placeholder="Enter Tier 2 Countries (separated by line breaks)"
                        required
                        value={tier2Countries}
                        onChange={(e) => setTier2Countries(e.target.value)}
                    ></textarea>
                    <textarea
                        rows={10}
                        className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full sm:w-[48%] text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                        placeholder="Enter Tier 2 Classes (separated by line breaks)"
                        required
                        value={tier2Classes}
                        onChange={(e) => setTier2Classes(e.target.value)}
                    ></textarea>
                </div>
                <h3 className="text-2xl text-black font-bold mb-5 mt-10">Tier 3</h3>
                <div className="block sm:flex justify-between">
                    <textarea
                        rows={10}
                        className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full sm:w-[48%] text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                        placeholder="Enter Tier 3 Countries (separated by line breaks)"
                        required
                        value={tier3Countries}
                        onChange={(e) => setTier3Countries(e.target.value)}
                    ></textarea>
                    <textarea
                        rows={10}
                        className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full sm:w-[48%] text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                        placeholder="Enter Tier 3 Classes (separated by line breaks)"
                        required
                        value={tier3Classes}
                        onChange={(e) => setTier3Classes(e.target.value)}
                    ></textarea>
                </div>
                <h3 className="text-2xl text-black font-bold mb-5 mt-10">Trademarks</h3>
                <textarea
                    rows={10}
                    className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                    placeholder="Enter Trademarks (separated by line breaks)"
                    required
                    value={trademarks}
                    onChange={(e) => setTrademarks(e.target.value)}
                ></textarea>
                <div className="mt-5 text-center">
                    <button className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5">Submit</button>
                </div>
            </form>
            {results ?
                <>
                    <h4 className="text-xl mt-8 text-black font-bold">Select Data Set</h4>
                    <select className="focus:outline-none w-full rounded-2xl py-2 mb-3 px-4 border-4 border-main text-primary"
                        onChange={(e) => setOption(e.target.value)}
                    >
                        <option value=""></option>
                        {results?.not_ranked_countries?.length && <option value="not_ranked_countries">Not Ranked Countries</option>}
                        {results?.tier_1_countries?.length && <option value="tier_1_countries">Tier 1 Countries</option>}
                        {results?.tier_2_countries?.length && <option value="tier_2_countries">Tier 2 Countries</option>}
                        {results?.tier_3_countries?.length && <option value="tier_3_countries">Tier 3 Countries</option>}
                    </select>
                    {results[option]?.length ?
                        <div className="overflow-x-auto">
                            <table className="w-full text-sm text-left border-main bg-green">
                                <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                                    <tr>
                                        <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Class</th>
                                        <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Class Priority</th>
                                        <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Class Tier</th>
                                        <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Country</th>
                                        <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Country Priority</th>
                                        <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Country Tier</th>
                                        <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Missing Trademark</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {results[option]?.map((item: any, index: any) => (
                                        <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main" key={index}>
                                            <td className="px-6 py-4 text-black border border-main">{item['Class']}</td>
                                            <td className="px-6 py-4 text-black border border-main">{item['Class Priority']}</td>
                                            <td className="px-6 py-4 text-black border border-main">{item['Class Tier']}</td>
                                            <td className="px-6 py-4 text-black border border-main">{item['Country']}</td>
                                            <td className="px-6 py-4 text-black border border-main">{item['Country Priority']}</td>
                                            <td className="px-6 py-4 text-black border border-main">{item['Country Tier']}</td>
                                            <td className="px-6 py-4 text-black border border-main">{item['Trade Mark']}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        : null}
                </>
                : null}
        </div>
    )
}
export default Form