import Form from "./components/Form"
import WrapperLayout from 'components/Layout';
const GapFill = ()=>{
    return(
        <>
        <WrapperLayout>
        <Form/>
        </WrapperLayout>
        </>
    )
}
export default GapFill