import { useState } from "react"
import { useDomainAnalysis } from "hooks/useDomainAnalysis";
const SendCompanyNames = () => {
    const [companyData, setCompanyData] = useState("")
    const { sendCompanyNames } = useDomainAnalysis()
    const [email, setEmail] = useState("")
    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            await sendCompanyNames(companyData?.split("\n"), email)
        }
        catch (e) {
            console.log(e)
        }
    }
    return (
        <div className="container mx-auto mt-10 ">
            <h3 className="text-2xl text-black font-bold mb-5">Enter Company Names</h3>
            <form onSubmit={handleFormSubmit}>
                <input type="email" className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl mb-3 focus:outline-none" placeholder="Enter Email" required value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <textarea
                    id="input"
                    rows={10}
                    className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                    placeholder="Enter Company Names (separated by line break)"
                    required
                    value={companyData}
                    onChange={(e) => setCompanyData(e.target.value)}
                ></textarea>
                <div className="my-5 text-center">
                    <button className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5">Submit</button>
                </div>
            </form>
        </div>
    )
}
export default SendCompanyNames