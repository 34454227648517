import BudgetTracker from "../BudgetTracker"
import { useState } from "react"
import Button from "components/Button"
type TProps = {
    data: any;
    setShowVideoStory: any;
    setTitle: any;
    setVideoLink: any;
    setShowVideoModal: any;
    setCaseRef: any;
    setOfficialNo: any;
    setSummary: any;
    setTranscript: any
}
const TableRows = ({ data, setShowVideoStory, setTitle, setVideoLink, setCaseRef, setShowVideoModal, setOfficialNo, setSummary, setTranscript }: TProps) => {
    const [videoPlayed, setVideoPlayed] = useState(false)
    const [cartNotes, setCardNotes] = useState<any>([])
    return (
        <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main">
            <td className="px-2 py-4 text-black border border-main text-center text-xs"><BudgetTracker budget={data.budget} cost={data.currentCost} /></td>
            <td className="px-2 py-4 text-black border border-main text-xs text-center">{data.case_ref}</td>
            <td className="px-2 py-4 text-black border border-main text-xs text-center">{data.country}</td>
            <td className="px-2 py-4 text-black border border-main text-xs text-center">{data.official_no}</td>
            <td className="px-2 py-4 text-black border border-main text-xs text-center">{data.title}</td>
            <td className="px-2 py-4 text-black border border-main text-xs text-center">{data.local_classes}</td>
            <td className="px-2 py-4 text-black border border-main text-xs text-center">{data.instructor}</td>
            <td className="px-2 py-4 text-black border border-main text-xs text-center">{data.registered_owner}</td>
            <td className="px-2 py-4 text-black border border-main text-xs text-center">{data.status}</td>
            <td className="px-2 py-4 text-black border border-main text-xs text-center">
                <video muted className={`video-trademark w-[50px] h-[50px] ${videoPlayed ? 'video-played' : 'video-not-played'} `} onClick={(e) => { setVideoPlayed(true); setShowVideoStory(true); setTitle(data.title); setVideoLink(data.video) }} onMouseEnter={(e: any) => e.target.play()} onMouseLeave={(e: any) => e.target.pause()}>
                    <source src={`${process.env.PUBLIC_URL}/assets/media/${data.video}`} type="video/mp4" />
                    <source src={`${process.env.PUBLIC_URL}/assets/media/${data.video}`} type="video/ogg" />
                    Your browser does not support HTML video.
                </video>
            </td>
            <td className="px-2 py-4 text-black border border-main text-xs text-center"><Button onClick={(e) => { setShowVideoModal(true); setTitle(data.title); setCaseRef(data.case_ref); setOfficialNo(data.official_no); setSummary(data.summary) }} title="Reply"></Button></td>
            <td className="px-2 py-4 text-black border border-main text-xs text-center">{!!data.transcript ? <a className='cursor-pointer' onClick={() => setTranscript(data.transcript)}> <u>View Transcript</u></a> : null}</td>
            <td className="px-2 py-4 text-black border border-main text-center" dangerouslySetInnerHTML={{ __html: data.summary }}></td>
            <td className="px-2 py-4 text-black border border-main text-xs text-center">
                {data.cartData.length > 0 ?
                    <div>
                        {data.cartData.map((item: any, index: number) => (
                            <div key={index} className="mb-3">
                                <input
                                    className='trademark-checkbox'
                                    type='checkbox'
                                    key={index}
                                    value={item}
                                    id={`default-${index}`}
                                    checked={cartNotes.indexOf(item) > -1}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setCardNotes([...cartNotes, item])
                                        }
                                        else {
                                            const index = cartNotes.indexOf(item)
                                            cartNotes.splice(index, 1)
                                            setCardNotes([...cartNotes])
                                        }
                                    }
                                    }
                                />
                                <label htmlFor={`default-${index}`} className="ms-2 text-xs font-medium text-xs">{item}</label>
                            </div>
                        ))}
                        {/* <Button type='submit' variant='primary' className='mt-3'>Submit</Button> */}
                    </div>
                    : null}
            </td>
        </tr>
    )
}
export default TableRows