import { Routes, Route, Navigate } from "react-router-dom";
import { PATHS } from "constants/index";
import Dashboard from "page/DashBoard";
import DomainAnalysis from "page/DomainAnalysis"
import BlockchainCertification from "page/BlockchainCertification"
import AddTradeMark from "page/AddTradeMark";
import Schedule from "page/Schedule";
import TrawlSection from "page/TrawlSection";
import GapFill from "page/GapFill";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.HOME} element={<Dashboard />} />
      <Route path={PATHS.PUNNYCODE} element={<DomainAnalysis />} />
      <Route path={PATHS.BLOCKCHAINCERTIFICATION} element={<BlockchainCertification/>}/>
      <Route path={PATHS.VERIFICATION} element={<AddTradeMark/>} />
      <Route path={PATHS.SCHEDULE} element={<Schedule/>} />
      <Route path={PATHS.TRAWLSECTION} element={<TrawlSection/>} />
      <Route path={PATHS.GAPFILL} element={<GapFill/>} />
      <Route path="*" element={<Navigate to={PATHS.HOME} />} />
    </Routes>
  );
};
